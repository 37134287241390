import AccordionBlock from 'components/organisms/accordionBlock'
import { KlaviyoBlock } from 'components/organisms/klaviyoBlock'
import TableBlock from 'components/organisms/tableBlock'
import React from 'react'
import styled from 'styled-components'

import { getRegionalKlaviyoID, getRegionalLink } from '../../helpers/locale'
import StyledButton from '../atoms/button'
import Link from '../atoms/link'
import StyledSecondaryButton from '../atoms/secondaryButton'
import CTABlock from '../organisms/ctaBlock'
import ImagesBlock from '../organisms/imagesBlock'
import VideoBlock from '../organisms/videoBlock'

type EmbeddedEntryType = {
  fields: any
  type: string
  children?: React.ReactNode
}

const StyledEmbeddedEntry = styled.div``

export const EmbeddedEntry = (props: EmbeddedEntryType): JSX.Element => {
  const { fields, type } = props

  switch (type) {
    case 'ContentfulImagesContentBlock': {
      return (
        <StyledEmbeddedEntry>
          <ImagesBlock
            items={fields.images}
            singleImageLayout={fields.singleImageLayout}
          />
        </StyledEmbeddedEntry>
      )
    }

    case 'ContentfulButton': {
      return (
        <StyledButton to={getRegionalLink(fields.regionallink)}>{fields.regionallink.title}</StyledButton>
      )
    }

    case 'ContentfulSecondaryButton': {
      return (
        <StyledSecondaryButton to={getRegionalLink(fields.regionallink)}>{fields.regionallink.title}</StyledSecondaryButton>
      )
    }

    case 'ContentfulRegionalLink': {
      return <Link to={getRegionalLink(fields)}>{fields.title}</Link>
    }

    case 'ContentfulCtaBlock': {
      return <CTABlock body={fields.body.body} cta={fields.cta} />
    }

    case 'ContentfulAsset': {
      return (
        <StyledEmbeddedEntry>
          <ImagesBlock
            items={[fields]}
            singleImageLayout={fields.singleImageLayout}
          />
        </StyledEmbeddedEntry>
      )
    }

    case 'ContentfulVideo': {
      return (
        <StyledEmbeddedEntry>
          <VideoBlock url={fields.url} />
        </StyledEmbeddedEntry>
      )
    }

    case 'ContentfulLink': {
      return <Link to={fields.link}>{fields.title}</Link>
    }

    case 'ContentfulPage': {
      return <Link to={`/${fields.slug}/`}>{fields.title}</Link>
    }

    case 'ContentfulArticle': {
      return <Link to={`/community/${fields.slug}/`}>{fields.title}</Link>
    }

    case 'ContentfulRegionalKlaviyoEmbedForm': {
      return (
        <StyledEmbeddedEntry>
          <KlaviyoBlock id={getRegionalKlaviyoID(fields)} />
        </StyledEmbeddedEntry>
      )
    }

    case 'ContentfulAccordion': {
      return (
        <StyledEmbeddedEntry><AccordionBlock items={fields.items} /></StyledEmbeddedEntry>
      )
    }

    case 'ContentfulTable': {
      return (
        <StyledEmbeddedEntry><TableBlock title={fields.title} hasHeader={fields.hasHeader} items={fields.table.tableData} /></StyledEmbeddedEntry>
      )
    }

    default:
      console.error(`Type "${type}" not implemented.`)

      return null
  }
}
